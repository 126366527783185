:root {
  --textColor: #f5f5f7;
  --backGroundColor: #0a0a0a;
  /* For Js */
  --backGroundColorOpacity: rgba(10, 10, 10, 1);
  --backgroundBeforeOpacity: 0.12;
  --dynamicWidth: 800px;
}

@font-face {
  font-family: 'mulish';
  src: url('./fonts/mulish-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'sf';
  src: url('./fonts/sf_bold.woff2') format('woff2');
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  /*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222430; /* linear-gradient(#3d3d3d, #000000); */
}

.MainCanvas {
  touch-action: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.htmlScreen ::-webkit-scrollbar,
.screenWrapper ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.htmlScreen,
.screenWrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: fixed !important; /* fix html drei scrolling of screen*/
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.htmlScreen {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--backGroundColorOpacity);
  transition: background-color 1.5s;
}

.htmlScreen:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: var(--backgroundBeforeOpacity);
  z-index: -1;
  background: transparent url('../public/img/grain.gif');
  background-repeat: repeat;
  background-size: 10%;
  transition: opacity 1.5s;
}

.fullwidth {
  width: 100%;
  height: 100%;
  background-color: var(--backGroundColor);
  overflow-y: scroll;
  overflow-x: hidden;
}

.view3,
.view2,
.view1 {
  height: 100%;
}

/* Welcome page (Page 1) */
.h1Wrapper {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  color: var(--textColor);
  font-size: 20vw;
  font-family: sf, arial;
  font-weight: 700;
  line-height: 140px;
}

@media (min-width: 900px) {
  h1 {
    font-size: 16vw;
  }
}

.view2 {
  display: flex;
  justify-content: center;
}

/* Bio page (Page 2) */
.textWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: mulish, arial;
  font-size: 28px;
  line-height: 1.7;
  color: #ffffff;
  text-align: justify;
  width: var(--dynamicWidth);
  max-width: 800px;
  transition: opacity 1s;
}
.textWrapper div {
  margin: 0 5% 0 5%;
}

@media (min-width: 1050px) {
  .textWrapper {
    margin: none;
  }
}

.unShow {
  opacity: 0;
  transition: all 1s;
}

.show {
  opacity: 1;
}
.s1 {
  transition: all 0.25s;
}
.s2 {
  transition: all 0.5s;
}
.s3 {
  transition: all 0.75s;
}
.s4 {
  transition: all 1s;
}
.s5 {
  transition: all 1.25s;
}
.s6 {
  transition: all 1.5s;
}

/* Launch page (Page 3) */

/* BTN */
.btnWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.btnInnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.myButton {
  box-shadow: 0px 0px 30px 4px #ffff; /* 1645e9 */
  background-color: #000000;
  border: 3px solid var(--backGroundColor);
  color: #ffffff;
  transition: 0.3s;

  border-radius: 150px;
  display: inline-block;
  cursor: pointer;
  font-family: sf, arial;
  letter-spacing: 0.05em;
  font-size: 35px;
  font-weight: bold;
  width: 175px;
  height: 175px;
  pointer-events: auto;
}

.myButton:disabled {
  pointer-events: none;
  animation: btnActionSmall 1s;
}

.myButton:hover:enabled {
  box-shadow: 0px 0px 45px 8px #ffff;
  background-color: #000000;
  border: 3px solid var(--backGroundColor);
  color: #ffffff;
}

@media (min-width: 1050px) {
  .myButton {
    font-size: 50px;
    width: 250px;
    height: 250px;
    letter-spacing: 0.05em;
  }
  .myButton:not(:active) {
    animation: btnActionBig 1s;
  }
}

@keyframes btnActionSmall {
  0% {
    box-shadow: 0px 0px 45px 8px #ffff;
  }
  50% {
    box-shadow: 0px 0px 55px 20px #ffff; /* 1645e9s */
    width: 150px;
    height: 150px;
    font-size: 31px;
  }
}
@keyframes btnActionBig {
  0% {
    box-shadow: 0px 0px 45px 8px #ffff;
  }
  50% {
    box-shadow: 0px 0px 55px 20px #ffff; /* 1645e9s */
    width: 225px;
    height: 225px;
    font-size: 45px;
  }
}

/* Animation */
.view3 {
  position: relative;
  user-select: none;
}

.fixedView {
  width: 2080px;
  height: 1043px;
  position: absolute;
  left: calc(-1040px + 50%);
}

.seat {
  position: absolute;
  left: 13%;
  top: 10%;
  width: 300px;
  height: auto;
  z-index: 1000;
  transition: filter 1s;
}

.arcade {
  position: absolute;
  right: 6%;
  bottom: 15%;
  width: 600px;
  height: auto;
  z-index: 1000;
  transition: filter 1s;
}

.pong {
  position: absolute;
  left: 40%;
  top: 5%;
  width: 340px;
  height: auto;
  z-index: 1000;
  transition: filter 1s;
}

.school {
  position: absolute;
  right: 20%;
  top: 10%;
  width: 300px;
  height: auto;
  z-index: 1000;
  transition: filter 1s;
}

.cube {
  position: absolute;
  right: 35%;
  bottom: 5%;
  width: 250px;
  height: auto;
  z-index: 1000;
  transition: filter 1s;
}

.desk {
  position: absolute;
  left: 15%;
  bottom: 5%;
  width: 500px;
  height: auto;
  z-index: 1000;
  transition: filter 1s;
}

.school:hover,
.pong:hover,
.desk:hover,
.seat:hover,
.cube:hover,
.arcade:hover {
  transition: filter 1s;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

/* White noise */
.whiteNoise {
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent url('../public/img/grain.gif') /*  repeat 0 0 */;
  /*  background-repeat: repeat; */
  opacity: 0.12;
  visibility: visible;
  pointer-events: none;
  z-index: 500;
}

/* Scroll */
#scroll-wrapper {
  position: absolute;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 9;
  pointer-events: none;
  /* left: 30%; */
}

#scroll-wrapper-inner {
  display: table-cell;
  vertical-align: bottom;
  width: 100%;
  height: 100%;
}

#scroll-down {
  display: block;
  position: relative;
  padding-top: 79px;
  text-align: center;
}

#scroll-title {
  display: block;
  text-transform: uppercase;
  color: var(--textColor);
  font-family: 'Courier-Prime', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4em;
  text-align: center;
  transform: rotate(90deg);
  margin-bottom: 45px;
}
#scroll-down::before {
  -webkit-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  -moz-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  -o-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 90px;
  background: #fff;
  content: ' ';
}
@-webkit-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-moz-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-o-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

/* Black & White To color */
.imgBlack {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.imgColor {
  animation: toColor 2s;
}

@keyframes toColor {
  from {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  to {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }
}

/* Annotations */
.title {
  text-align: center;
  font-family: sf, arial;
  font-size: 28px;
  font-weight: 700;
}

.ligne {
  border-bottom: solid 5px #05809f;
  border-radius: 3px;
  margin: 5px 80px 0px 80px;
}

.textLignes {
  font-family: mulish, arial;
  font-size: 18px;
  color: #ffffff;
}

.content {
  padding-top: 10px;
  transform: translate3d(calc(40px), 0, 0);
  text-align: left;
  background: #202035;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  width: 300px;
}

.content::before {
  content: '';
  position: absolute;
  top: 25px;
  left: -40px;
  height: 3px;
  width: 40px;
  border-radius: 3px;
  background: #202035;
}

.contentMobile {
  padding-top: 10px;
  text-align: left;
  background: #202035;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  width: 300px;
}

.contentBottom {
  padding-top: 10px;
  transform: translate3d(calc(40px), -100%, 0);
  text-align: left;
  background: #202035;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  width: 300px;
}

.contentBottom::before {
  content: '';
  position: absolute;
  bottom: 25px;
  left: -40px;
  height: 3px;
  width: 40px;
  border-radius: 3px;
  background: #202035;
}

.contentLeft {
  padding-top: 10px;
  transform: translate3d(calc(-100% - 40px), 0, 0);
  text-align: left;
  background: #202035;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  width: 300px;
}

.contentLeft::before {
  content: '';
  position: absolute;
  top: 25px;
  right: -40px;
  height: 3px;
  width: 40px;
  border-radius: 3px;
  background: #202035;
}

.wrapAnnotation {
  position: fixed !important;
  pointer-events: none;
}

.wrapAnnotationMobile {
  position: fixed !important;
  pointer-events: none;
}

/* Pong screan */
.pongScrean {
  width: 346px;
  height: 307px;
  border: solid 2px #fff;
  margin: 0 auto;
  top: 5em;
  overflow: hidden;
}

#half {
  position: absolute;
  width: 0px;
  height: 307px;
  border-left: dotted 2px #fff;
  left: 50%;
  z-index: 1;
}

#sidel {
  position: absolute;
  width: 6px;
  height: 50px;
  background-color: #fff;
  left: 13px;
  top: 40px;
  animation: sideleft 3s infinite alternate ease-in-out;
}

#sider {
  position: absolute;
  width: 6px;
  height: 50px;
  background-color: #fff;
  left: 327px;
  top: 190px;
  animation: sideright 3s infinite ease-in-out;
}

#ball {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #fff;
  left: 322px;
  top: 250px;
  animation: pong 3s infinite linear;
}

@keyframes sideleft {
  0% {
    top: 12px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 224px;
  }
  75% {
    top: 212px;
  }
  100% {
    top: 12px;
  }
}

@keyframes sideright {
  0% {
    top: 125px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 150px;
  }
  60% {
    top: 237px;
  }
  100% {
    top: 125px;
  }
}

@keyframes pong {
  0% {
    left: 18px;
    top: 37px;
  }
  5% {
    left: 35px;
    top: 0px;
  }
  55% {
    left: 322px;
    top: 237px;
  }
  65% {
    left: 288px;
    top: 300px;
  }
  100% {
    left: 18px;
    top: 37px;
  }
}
/* screen */
.arcadeFrame {
  border: none;
  width: 915px;
  height: 585px;
}

.presentationFrame {
  border: none;
  width: 1038px /* 815px */;
  height: 800px /*  815px */;
}

/* Annotation indication */
.fireflyWrapper {
  display: relative;
}

.firefly {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  animation: fireflyAnime 3s infinite ease;
  top: 3px;
  left: 3px;
}

@keyframes fireflyAnime {
  0% {
    box-shadow: 0px 0px 0px 0px #ffffff00;
  }
  50% {
    box-shadow: 0px 0px 55px 10px #ffffff;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #ffffff00;
  }
}

.fireflyInner {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  animation: fireflyInnerAnime 3s infinite ease;
}

@keyframes fireflyInnerAnime {
  0% {
    box-shadow: inset 0px 0px 50px 50px #ffffff00;
  }
  50% {
    box-shadow: inset 0px 0px 50px 50px #ffffff;
  }
  100% {
    box-shadow: inset 0px 0px 50px 50px #ffffff00;
  }
}

/* IHM */
.Ihm {
  position: absolute;
  z-index: 9999999;
  bottom: 0px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;
}

.Ihm .buttons {
  display: flex;
  justify-content: center;
  user-select: none;
}

.Ihm img {
  width: 50px;
  height: 50px;
}

.arrowDown,
.arrowUp,
.arrowUpLight {
  transition: opacity 1s, box-shadow 0.3s;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowDown {
  margin-right: 30px;
}

.arrowUp {
  margin-left: 30px;
}

.arrowUpLight {
  animation: arrowUpLightAnimation 3s infinite;
  margin-left: 30px;
}

@media (max-width: 1100px) {
  .Ihm img {
    width: 40px;
    height: 40px;
  }

  .arrowDown,
  .arrowUp,
  .arrowUpLight {
    width: 36px;
    height: 36px;
  }

  .arrowDown {
    margin-right: 20px;
  }

  .arrowUp {
    margin-left: 20px;
  }

  .arrowUpLight {
    margin-left: 20px;
  }
}

@media (hover: hover) {
  .arrowDown:hover,
  .arrowUp:hover {
    box-shadow: 0px 0px 15px white;
  }
}

.Ihm .sceneTitle {
  color: white;
  font-family: sf, arial;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 1100px) {
  .Ihm .sceneTitle {
    font-size: 40px;
  }

  .Ihm {
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .Ihm .sceneTitle {
    font-size: 30px;
  }

  .Ihm {
    margin-bottom: 20px;
  }

  .Ihm img {
    width: 35px;
    height: 35px;
  }

  .arrowDown,
  .arrowUp,
  .arrowUpLight {
    width: 33px;
    height: 33px;
  }
}

@keyframes arrowUpLightAnimation {
  0% {
    box-shadow: 0px 0px 0px white;
  }
  50% {
    box-shadow: 0px 0px 20px white;
  }
}

.bioLinks {
  width: 535px;
  height: 535px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.github,
.linkedin {
  transition: box-shadow 0.3s;
  user-select: none;
  position: relative;
}

@media (hover: hover) {
  .github:hover:before {
    content: '';
    box-shadow: 0px 0px 40px white;
    border-radius: 150px;
    position: absolute;
    top: 4px;
    width: 230px;
    height: 225px;
  }

  .linkedin:hover:before {
    content: '';
    box-shadow: 0px 0px 35px white;
    border-radius: 150px;
    position: absolute;
    width: 230px;
    height: 230px;
  }
}
